import React, { Component, Suspense, lazy } from "react";
import { Route, HashRouter, Switch, Redirect } from "react-router-dom";

const IndexComp = lazy(() => import("./views/Index/Index"));
const IntroduceComp = lazy(() => import("./views/Introduce/Introduce"));
const SceneComp = lazy(() => import("./views/Scene/Scene"));
// const ThemeSpace = lazy(() => import("./views/ThemeSpace/ThemeSpace"));
// const BiadIntroduce = lazy(() => import("./views/BiadIntroduce/Index"));
// const QiLinIntroduce = lazy(() => import("./views/QiLinIntroduce/Index"));

const ComList = [
    {
        path: "/index",
        comp: IndexComp
    },
    {
        path: "/introduce",
        comp: IntroduceComp
    },
    {
        path: "/scene/:themeId/:spaceId/:pathId",
        comp: SceneComp
    },
    // {
    //     path: "/themeSpace/:themeId",
    //     comp: ThemeSpace
    // },
    // {
    //     path: "/BiadIntroduce",
    //     comp: BiadIntroduce
    // },
    // {
    //     path: "/QiLinIntroduce",
    //     comp: QiLinIntroduce
    // }
];

class RouterComponent extends Component {
    render() {
        return (
            <HashRouter>
                <Suspense fallback={<div></div>}>
                    <Switch>
                        {
                            ComList.map((item, ind) => {
                                return <Route key={ind} path={item.path} component={item.comp}></Route>
                            })
                        }
                        <Redirect from="/" to="/index" />
                    </Switch>
                </Suspense>
            </HashRouter>
        );
    }
}

export default RouterComponent;